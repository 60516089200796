import { createRouter, createWebHistory } from 'vue-router'

import Home from '@/views/home/HomeIndex.vue'
import My from '@/views/my/MyIndex.vue'
import Order from '@/views/order/OrderIndex.vue'

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/order',
    component: Order
  },
  {
    path: '/my',
    component: My
  }
]

const routerHistory = createWebHistory('/publish-test1/')
const router = createRouter({
  history: routerHistory,
  routes
})

export default router
